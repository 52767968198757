<style scoped>
/* Set the page size to A4 and the orientation to landscape */
@page {
  size: letter portrait;
}

/* Set the font size to 12px for printing */
@media print {
  body {
    font-size: 12px;
  }

  .page-break {
    page-break-before: always;
    counter-reset: page;
  }

  @page :footer {
    display: none;
  }
}

#logo {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 200px;
  height: 100px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#no-border {
  border: none;
}

td,
th.bordered {
  border: 1px solid #000000;
  text-align: left;
  padding: 8px;
}

th.no-border {
  border: none;
}

.remove_border tr th td {
  border: none;
}

#table-list {
  margin-top: 80px;
}

.info {
  margin-top: 20px;
  width: 40%;
  float: left;
  margin-bottom: 20px;
}

.space-for-logo {
  width: 200px;
  height: 100px;
}

#page-counter {
  display: table-footer-group;
}

#page-counter:after {
  counter-increment: page;
  content: counter(page) " of " counter(page);
}
</style>

<template>
  <div>
    <div class="page-break" :key="idx" v-for="(data, idx) in datas">
      <table style="width: 100%">
        <thead>
          <tr>
            <th colspan="7">
              <div class="title">
                <p>Proforma Invoice</p>
                <p>PT. SINARMAS DISTRIBUSI NUSANTARA</p>
              </div>
            </th>
          </tr>
          <tr>
            <th class="py-0" colspan="2">Alamat</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ data.Company ? data.Company.Address : "" }}</th>
            <th class="py-0">NO. DO / NO. SO</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{
                data.DeliveryOrder
                  ? `${data.DeliveryOrder.Code} / ${data.DeliveryOrder.SalesOrder.Code}`
                  : ""
              }}
            </th>
          </tr>
          <tr>
            <th class="py-0" colspan="2">N.P.W.P</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ data.Company ? data.Company.Npwp : "" }}</th>
            <th class="py-0">Tgl. Transaksi</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ formatDate(data.Date) }}</th>
          </tr>
          <tr>
            <th class="py-0" colspan="2">No. Pengukuhan PKP</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ data.Company ? data.Company.Npwp : "" }}</th>
            <th class="py-0">Syarat Pembayaran</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{
                data.DeliveryOrder
                  ? data.DeliveryOrder.SalesOrder.PaymentTermName
                  : ""
              }}
            </th>
          </tr>
          <tr>
            <th class="py-0" colspan="2">Tgl. Pengukuhan PKP</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ "-" }}</th>
            <th class="py-0">Tgl. Kadaluarsa</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{
                data.DeliveryOrder
                  ? formatDate(data.DeliveryOrder.SalesOrder.RDD)
                  : ""
              }}
            </th>
          </tr>
          <tr>
            <th class="py-0" colspan="2">Vendor Transportir</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ "-" }}</th>
            <th class="py-0">PO No.</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{
                data.DeliveryOrder
                  ? data.DeliveryOrder.SalesOrder.PoReferenceCode
                  : ""
              }}
            </th>
          </tr>
          <tr>
            <th class="py-0" colspan="2">Vehicle Number</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{
                data.DeliveryOrder
                  ? data.DeliveryOrder.TransportDeliveryPlanLine
                      .TransportPlanLine.TransportPlan.DriverAvailability
                      .Vehicle.Number
                  : ""
              }}
            </th>
            <th class="py-0">Kode/ Salesman</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{
                data.DeliveryOrder
                  ? data.DeliveryOrder.SalesOrder.SalesPersonalName
                  : ""
              }}
            </th>
          </tr>
          <tr>
            <th class="py-0" colspan="2"></th>
            <th class="py-0"></th>
            <th class="py-0"></th>
            <th class="py-0">Ref. No.</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{
                data.DeliveryOrder
                  ? data.DeliveryOrder.SalesOrder.PoReferenceCode
                  : ""
              }}
            </th>
          </tr>
          <tr>
            <th class="py-0" colspan="2">Kepada Yth</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{
                data.Customer
                  ? `${data.Customer.code} ${data.Customer.name} ${data.Customer.address}`
                  : ""
              }}
            </th>
            <th class="py-0">Print</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ data.DeliveryOrder.PrintCounter }}</th>
          </tr>
          <tr>
            <th class="py-0" colspan="2">NPWP</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ data.Customer ? data.Customer.npwp : "" }}</th>
            <th class="py-0">Print date</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ dateNow }}</th>
          </tr>
          <tr>
            <th class="py-0" colspan="2"></th>
            <th class="py-0"></th>
            <th class="py-0"></th>
            <th class="py-0">Print time</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ timeNow }}</th>
          </tr>

          <tr>
            <th
              class="py-1 bordered"
              style="vertical-align: middle; text-align: center"
            >
              No.
            </th>
            <th
              class="py-1 bordered"
              style="vertical-align: middle; text-align: center"
            >
              Item
            </th>
            <th
              class="py-1 bordered"
              style="vertical-align: middle; text-align: center"
              colspan="2"
            >
              Item Name
            </th>
            <th
              class="py-1 bordered"
              style="vertical-align: middle; text-align: center"
            >
              Qty
            </th>
            <th
              class="py-1 bordered"
              style="vertical-align: middle; text-align: center"
            >
              UoM
            </th>
            <th
              class="py-1 bordered"
              style="vertical-align: middle; text-align: center"
            >
              Price
            </th>
          </tr>
        </thead>
        <!-- <tbody v-if="data.DeliveryOrder">
          <tr
            :key="idx"
            v-for="(tr, idx) in data.DeliveryOrder.DeliveryOrderLine"
          >
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ idx + 1 }}
            </td>
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ tr.PackingLine.SkuCode }}
            </td>
            <td
              class="py-1"
              style="vertical-align: middle; text-align: center"
              colspan="2"
            >
              {{ tr.PackingLine.ItemName }}
            </td>
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ tr.PackingLine.Amount }}
            </td>
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ tr.PackingLine.ItemUnit }}
            </td>
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ formatNumber(tr.Price) }}
            </td>
          </tr>
        </tbody> -->

        <tbody v-if="data.oms_dol">
          <tr :key="idx" v-for="(tr, idx) in data.oms_dol">
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ idx + 1 }}
            </td>
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ tr.item_code }}
            </td>
            <td
              class="py-1"
              style="vertical-align: middle; text-align: center"
              colspan="2"
            >
              {{ tr.item_name }}
            </td>
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ tr.quantity }}
            </td>
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ tr.item_unit }}
            </td>
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ formatNumber(tr.total) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td
              class="py-1"
              style="vertical-align: middle; text-align: right"
              colspan="6"
            >
              Total Price
            </td>
            <td class="py-1" style="vertical-align: middle; text-align: center">
              {{ formatNumber(data.oms_do.Total) }}
            </td>
          </tr>
        </tfoot>
      </table>
      Messages :
      <br />
      <table class="remove_border">
        <tr>
          <th style="text-align: center">
            {{
              formatDate(
                data.DeliveryOrder.TransportDeliveryPlanLine.TransportPlanLine
                  .TransportPlan.Handover.Date
              )
            }}, <br />
            Yang Menyerahkan
          </th>
          <th style="text-align: center">Ekspedisi</th>
          <th style="text-align: center">
            Tanggal diterima : . . . . . . . . . . . . . <br />
            Yang Menerima
          </th>
        </tr>
        <tr>
          <th style="height: 50px"></th>
          <th style="height: 50px"></th>
          <th style="height: 50px"></th>
        </tr>
        <tr>
          <th style="text-align: center">
            ( . . . . . . . . . . . . . . . . . ) <br />
            Nama Jelas
          </th>
          <th style="text-align: center">
            ( . . . . . . . . . . . . . . . . . ) <br />
            Nama Jelas
          </th>
          <th style="text-align: center">
            ( . . . . . . . . . . . . . . . . . ) <br />
            Nama Jelas dan Cap
          </th>
        </tr>
      </table>
      <br />
      <!-- <div class="w-full inline-flex justify-center">Cetakan ke {{ data.DeliveryOrder.PrintCounter }}</div> -->
      <!-- <div class="">Cetakan ke {{ data.DeliveryOrder.PrintCounter }}</div> -->
      <div>Pemberitahuan Penting:</div>
      <div>1. Surat Jalan ini bukan merupakan bukti pembayaran yang sah.</div>
      <div>
        2. Mintalah invoice asli dan faktur pajak setiap kali akan melakukan
        pembayaran.
      </div>
      <div>
        3. Pembayaran tanpa disertai bukti yang sah merupakan tanggung jawab
        PELANGGAN sepenuhnya.
      </div>
      <!-- <table style="page-break-inside: avoid" class="remove_border">
        <tr>
          <td>
            <div>Pemberitahuan Penting:</div>
            <div>
              1. Surat Jalan ini bukan merupakan bukti pembayaran yang sah.
            </div>
            <div>
              2. Mintalah invoice asli dan faktur pajak setiap kali akan
              melakukan pembayaran.
            </div>
            <div>
              3. Pembayaran tanpa disertai bukti yang sah merupakan tanggung
              jawab PELANGGAN sepenuhnya.
            </div>
          </td>
        </tr>
      </table> -->
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
  name: "PrintPdf",
  data() {
    return {
      id: null,
      doIds: [],
      table: [],
      selectedDestWarehouse: null,
      // logoSDN: logoSDN,
      // data: {
      //     // Company: {},
      //     // Customer: {},
      //     // DeliverOrder: [],
      // },
      datas: [],
      dateNow: "",
      timeNow: "",
    };
  },
  mounted() {
    var now = moment();
    now.add(1, "days");

    this.reqDeliveryDate = now.toDate();
    this.eta = now.toDate();

    this.doIds = window.opener.doIds;
    if (this.doIds.length) {
      // this.getData()
      this.print();
    }
  },
  methods: {
    async print() {
      const tempTitle = document.title;
      await this.getData();
      await window.print();
      document.title = tempTitle;
    },
    async getData() {
      console.log("doIds", this.doIds);
      this.$vs.loading();
      let resp = await this.$http.get(
        `/api/wms/v1/simple-outbound-planner/surat-jalan`,
        {
          params: {
            do_ids: this.doIds,
            is_print: 1,
          },
        }
      );
      if (resp.code == 200) {
        this.datas = resp.data;
        document.title = this.datas.reduce(
          (prev, curr) => `${prev}-${curr.DeliveryOrder.Code}`,
          "Surat_Jalan"
        );
        console.log("data", this.data);
        const now = new Date();
        this.dateNow = this.formatDate(now.setHours(now.getHours() + 7));
        this.timeNow = this.formatTime(now);
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
  },
  computed: {
    formatDate: () => {
      return (val) => {
        const momentDate = moment.utc(val);
        // momentDate.add(1, 'days');              // Tambahkan 1 hari
        return momentDate.format("DD/MM/YYYY");
      };
    },
    formatTime: () => {
      return (val) => {
        const momentDate = moment.utc(val);
        return momentDate.format("hh:mm:ss");
      };
    },
    formatNumber: () => {
      return (val) => {
        return Intl.NumberFormat("en", { minimumFractionDigits: 2 }).format(
          val
        );
      };
    },
  },
};
</script>
